<template>
  <b-row>
    <b-col md="10">
      <b-card>
        <b-row class="border">
          <b-col
            md="3"
            class="border-r"
          >
            <span class="flex align-items-center justify-between">
              <b-img
                :src="appLogoImage"
                alt="logo"
                height="65px"
                width="65px"
              />
              <h2 class="mb-0 ml-1 text-primary font-large">
                {{ appName }}
              </h2>
            </span>
            <span class="flex justify-between font-small-3 mt-1">
              <p class="mb-0">Pantaleón S.A.</p>
              <p class="mb-0">Concepción, S.A.</p>
            </span>
          </b-col>
          <b-col
            md="9"
            class="align-items-center flex py-0"
          >
            <b-row>
              <b-col
                md="12"
                class="border-b"
              >
                <h4 class="text-center mb-0 line-height-condensed">
                  REGISTRO DE REVISIÓN PREVENTIVA DEL FUNCIONAMIENTO DE LOS DETECTORES DE METALES DE FLUJO VERTICAL
                </h4>
              </b-col>
              <b-col
                md="5"
                class="flex justify-between"
              >
                <span>Actualizacion 6</span><span>01/11/2022</span>
              </b-col>
              <b-col
                md="2"
                class="border-l border-r"
              >
                9-AT-R022
              </b-col>
              <b-col md="5">
                Correlativo No.
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8" />
          <b-col
            md="4"
          >
            <div class="flex align-items-center justify-between">
              <p class="m-0">
                Fecha y Hora:
              </p>
              <flat-pickr
                v-model="date"
                class="form-control"
                style="width:60%"
                :config="{ enableTime:true, dateFormat: 'Y-m-d H:i'}"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h5>1. Datos de Equipo</h5>
            <div
              class="flex mx-auto align-items-center justify-between mb-2"
              style="width:80%"
            >
              <p class="m-0">
                Descripción del Equipo:
              </p>
              <b-form-input
                v-model="desc_equipo"
                style="width:75%"
              />
            </div>
            <div
              class="mx-auto flex align-items-center mb-2"
              style="width:80%"
            >
              <div class="w-1/2 flex align-items-center justify-between">
                <p class="m-0">
                  Marca:
                </p>
                <b-form-input
                  v-model="marca"
                  style="width:80%"
                />
              </div>
              <div class="w-1/2 flex align-items-center justify-end">
                <p class="m-0">
                  S/N: &nbsp;
                </p>
                <b-form-input
                  v-model="s_n"
                  style="width:55%"
                />
              </div>
            </div>
            <div
              class="mx-auto flex align-items-center"
              style="width:80%"
            >
              <div class="w-1/2 flex align-items-center justify-between">
                <p class="m-0">
                  Modelo:
                </p>
                <b-form-input
                  v-model="modelo"
                  style="width:80%"
                />
              </div>
              <div class="w-1/2"></div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h5>1. Datos de Equipo</h5>
            <div
              class="flex mx-auto align-items-center justify-between mb-2"
              style="width:80%"
            >
              <p class="m-0">
                Descripción del Equipo:
              </p>
              <b-form-input
                v-model="desc_equipo"
                style="width:75%"
              />
            </div>
            <div
              class="mx-auto flex align-items-center mb-2"
              style="width:80%"
            >
              <div class="w-1/2 flex align-items-center justify-between">
                <p class="m-0">
                  Marca:
                </p>
                <b-form-input
                  v-model="marca"
                  style="width:80%"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="2">
      <b-card no-body />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BImg, BFormInput,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCol, BRow, BCard, BCardText, BImg, flatPickr, BFormInput,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      date: '',
      desc_equipo: '',
      marca: '',
      s_n: '',
      modelo: '',
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
